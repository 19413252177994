import Col from "react-bootstrap/Col";

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import validator from "validator";
import { motion } from "framer-motion";
import auth from "../../../helpers/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosConfig from "../../../helpers/axiosConfig";
import axiosWithCacheConfig from "../../../helpers/axiosWithCacheConfig"
import Modal from "../../../components/Modal";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import photoHelper from "../../../helpers/photoHelper";
import { setImage } from "../../../helpers/imageState";
import { useDispatch } from "react-redux";

import cross from "../../../assets/images/others/cross.svg"
import hidePw from "../../../assets/images/profile/hide-password.svg"
import showPw from "../../../assets/images/profile/show-password.svg"
import greenCircle from "../../../assets/images/profile/green-circle.svg"

function UserCardDetails(props) {
  const { setVerifiedStatus, setUserData, SetErrorRetrieving,SetDetailChanged,SetEmailSendingStatus} = props;
  const dispatch = useDispatch();
  const settingStatus = {autoClose: 3000,position:"bottom-right",closeButton:"x",closeOnClick: true,pauseOnFocusLoss:true};

  const [initialValue, SetInitialValue] = useState({
    fullname: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    fullname: [],
    email: [],
    oldPassword: [],
    newPassword: [],
    confirmPassword: [],
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
    newPassword: false,
  });
  const [isVerified, SetIsVerified] = useState(false);
  const navigate = useNavigate();

  const [userIsLoading, setUserIsLoading] = useState(false);
  const in_displayname = useRef();
  const in_email = useRef();
  const in_oldPassword = useRef();
  const in_newPassword = useRef();
  const in_confirmPassword = useRef();
  const [detailsUpdated, setDetailsUpdated] = useState(false);
  const profilepic = useRef();
  const [croppedImage, setCroppedImage] = useState(null);
  const [picView,setPicView] = useState(null)
  const ShowError = (field) => {
    let { errors } = field;

    // eslint-disable-next-line
    let listItem = Object.keys(errors).map((i) => (
      <div key={i.toString()} className="col-12 text-end">
        {errors[i]}
      </div>
    ));
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="flex-fill ps-1 text-red FS10  justify-content-end d-flex flex-wrap align-items-center"
      >
        {listItem}
      </motion.div>
    );
  };
  
  const detectChanges = ()=>{
   
    let changestatus = false;

    if(in_displayname.current.value.trim() !== initialValue.fullname){
      changestatus = true;
    }
    else if(in_email.current.value.trim() !== initialValue.email){
      changestatus = true;
    }
    else if(croppedImage !== null){
      changestatus = true;
    }
    else{
      changestatus = false;
    }
    SetDetailChanged(changestatus);
  }
  const validateField = (e) => {

    const { name } = e.target === undefined ? e.current : e.target;
    detectChanges();

    // if(initialValue[name] !== value){
    //   SetDetailChanged(true)
    // }else{
    //   console.log(value)
    //   SetDetailChanged(false)
    // }
    let tpl = errors;
    switch (name) {
      case "fullname":
        tpl.fullname = [];
        if (validator.isEmpty(in_displayname.current.value)) {
          tpl.fullname.push("Fullname must not be empty");
        } else {
          tpl.fullname = [];
        }

        break;
      case "email":
        tpl.email = [];
        if (validator.isEmpty(in_email.current.value)) {
          tpl.email.push("Email must not be empty");
          break;
        } else {
          tpl.email = [];
        }
        if (!validator.isEmail(in_email.current.value)) {
          tpl.email.push("Email is not valid!");
        } else {
          tpl.email = [];
        }

        break;

      case "oldPassword":
        tpl.oldPassword = [];
        if (validator.isEmpty(in_oldPassword.current.value)) {
          tpl.oldPassword.push("Old password must not be empty");
          break;
        } else if (
          validator.isLength(in_oldPassword.current.value, { max: 7 })
        ) {
          tpl.oldPassword.push("Your password should be at least 8 characters");
          break;
        } else if (
          !validator.isStrongPassword(in_oldPassword.current.value, {
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          tpl.oldPassword.push("Password is not strong enough");
          break;
        } else {
          tpl.oldPassword = [];
        }

        break;
      case "newPassword":
        tpl.newPassword = [];
        if (validator.isEmpty(in_newPassword.current.value)) {
          tpl.newPassword.push("New password must not be empty");
          break;
        } else if (
          validator.isLength(in_newPassword.current.value, { max: 7 })
        ) {
          tpl.newPassword.push("Your password should be at least 8 characters");
          break;
        } else if (
          !validator.isStrongPassword(in_newPassword.current.value, {
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          })
        ) {
          tpl.newPassword.push("Password is not strong enough");
          break;
        } else {
          tpl.newPassword = [];
        }
        break;

      case "confirmPassword":
        tpl.confirmPassword = [];
        if (validator.isEmpty(in_confirmPassword.current.value)) {
          tpl.confirmPassword.push("Re-enter your new password is required");
          break;
        } else if (
          validator.isLength(in_confirmPassword.current.value, { max: 7 })
        ) {
          tpl.confirmPassword.push(
            "Your password should be at least 8 characters"
          );
        } else if (
          !validator.equals(
            in_confirmPassword.current.value,
            in_newPassword.current.value
          )
        ) {
          tpl.confirmPassword.push("Your new password does not match!");
        } else {
          tpl.confirmPassword = [];
        }

        break;
      default:
        break;
    }

    setErrors({ ...errors, tpl });
  };

  const submitForm = (e) => {
    e.preventDefault();
   
    validateField(in_displayname);
    validateField(in_email);
    if (
      in_oldPassword.current.value === "" &&
      in_newPassword.current.value === "" &&
      in_confirmPassword.current.value === ""
    ) {
      updateFunction();
      return;
    }
    validateField(in_oldPassword);
    validateField(in_newPassword);
    validateField(in_confirmPassword);
    updateFunction();
  };
  const updateFunction = () => {
    let countErr = 0;
    Object.keys(errors).map((i, d) => {
      if (errors[i].length > 0) {
        countErr++;
      }
      return i;
    });
    if (countErr > 0) {
      console.log("unable to update");
    } else {
      setUserIsLoading(true);
      let data = new FormData();
      data.append("fullname", in_displayname.current.value.trim());
      data.append("email", in_email.current.value.trim());
      data.append("oldPassword", in_oldPassword.current.value);
      data.append("newPassword", in_newPassword.current.value);
      data.append("confirmPassword", in_confirmPassword.current.value);
      data.append("image", croppedImage);
     
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ?' http://127.0.0.1:3000/op/v1/user/update/':"https://api.onepeople.online/op/v1/user/update/";
      axiosWithCacheConfig
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        })
        .then((e) => {
          setUserIsLoading(false);
          SetInitialValue({
            ...initialValue,
            fullname: in_displayname.current.value,
            email: in_email.current.value,
          });
          setDetailsUpdated(true);
          SetDetailChanged(false)

          // setTimeout(function () {
          //   setDetailsUpdated(false);
          // }, 3000);
          in_oldPassword.current.value = "";
          in_newPassword.current.value = "";
          in_confirmPassword.current.value = "";
          fetchDetails();

          if(croppedImage){
            photoHelper.set(croppedImage);
            setDetailsUpdated(true)
            dispatch(setImage(croppedImage))
            profilepic.current.value = "";
          }
        })
        .catch((e) => {
          auth.checkErrors(e);
          setUserIsLoading(false);
          let err = e.response.data !== undefined ? e.response.data.errors: null;
          let msg = "";
          let temp = errors;
         
          if(err !== null){
            Object.keys(err).map(function (i) {
              // console.log(err[i]);
              if (err[i].msg === "Your current password is incorrect") {
                msg = err[i].msg;
                temp.oldPassword.push(msg);
                setErrors({ ...errors, temp });
              
              }
              else if (err[i].msg === "Email already taken") {
                msg = err[i].msg;
                temp.email.push(msg);
                setErrors({ ...errors, temp });
              }
              else if (err[i].msg === "Only 3 changes per day allowed.") {
                msg = err[i].msg;
                temp.email.push(msg);
                setErrors({ ...errors, temp });
              }else{
                toast(<>Oops! Something went wrong.</>, settingStatus);
              }
              
              return i;
            });
          }
    
        });
    }
  };
  const togglePasswordInput = (t, e) => {

    e.preventDefault();
   
    setShowPassword({ ...showPassword, [t]: !showPassword[t] });
  };
  const validatePasswordChange = () => {
   
    if (
      in_oldPassword.current.value === "" &&
      in_newPassword.current.value === "" &&
      in_confirmPassword.current.value === ""
    ) {
      setErrors({
        ...errors,
        oldPassword: [],
        newPassword: [],
        confirmPassword: [],
      });
      return;
    }
    validateField(in_oldPassword);
    validateField(in_newPassword);
    validateField(in_confirmPassword);
  };
  const fetchDetails = () => {
    setUserIsLoading(true);
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ?' http://127.0.0.1:3000/op/v1/user/':'https://api.onepeople.online/op/v1/user/';
   
    axiosWithCacheConfig
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
        setUserIsLoading(false);
        let { user } = e.data;
        if (!user.length > 0) return;
        user = user[0];
        SetInitialValue({
          ...initialValue,
          fullname: user.fullname,
          email: user.email,
        });
        setUserData(user);
        SetEmailSendingStatus(user.disable_email);
        setVerifiedStatus(user.email_verified_at !== null);
        
        SetIsVerified(user.email_verified_at !== null);
      })
      .catch((err) => {
        auth.checkErrors(err);
        SetErrorRetrieving(true);
       
        toast(<>Something went wrong!</>,settingStatus);
      });
  };

  // const toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = reject;
  //   });
  const [src, setSrc] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const cropperRef = useRef(null);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // eslint-disable-next-line
  const [rotation, setRotation] = useState(0);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSubmitPhoto = async (e) => {
    e.preventDefault();
    // let file = profilepic.current?.files;

    const croppedImage = await getCroppedImg(src, croppedAreaPixels, rotation);
    setCroppedImage(croppedImage);
    setPicView(croppedImage);

    // let image = await toBase64(file[0]);
    setSrc(null);
   
    SetDetailChanged(true)

  };


  // const getPhoto = () => {
  //   axiosConfig
  //     .get("https://api.onepeople.online/op/v1/photo", {
  //       headers: {
  //         Authorization: `Bearer ${auth.getToken()}`,
  //       },
  //     })
  //     .then((e) => {
  //       setCroppedImage(base64ToBlobUrl(e.data));
  //     });
  // };
  const loadPhoto = async ()=>{
    let photo =await photoHelper.get();
    if(photo !== null){
      if(photo.base64 !== undefined){
        dispatch(setImage(photo.base64))
      }
      if(photo.blob !== undefined){
        setPicView(photo.blob)
      }
    }
  
  }
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

 
  useEffect(() => {
    if (auth.getToken() === null) {
      navigate("/");
    }
    fetchDetails();
    loadPhoto();
  }, 
  // eslint-disable-next-line
  []);

  return (
    <>
    {detailsUpdated &&  <Modal>
            <motion.div
             transition={{ duration: 0.1 }}
             initial={{ opacity: 0 }}
             animate={{ opacity: 1 }}
          
              className="unsave-modal" onClick={()=>{setDetailsUpdated(false)}}>
              <div className="unsave-details text-center" onClick={e=>e.stopPropagation()} >
                <span className="close"  onClick={()=>{setDetailsUpdated(false)}}>
                  <img
                    src="/static/media/cross.d23451f0a9f25b15677944f023d34cc6.svg"
                    className="exit-icon"
                    alt="Exit Modal"
                  />
                </span>
                <h3 className="mb-5 text-black bold">
                Your details have been updated.
                </h3>
                <h4 className="text-black bold mb-4">📝 Details Updated</h4>
                <div className="circle justify-content-center d-flex">
                  <div>
                    <img src={greenCircle} className="img-fluid" alt="Success" />
                  </div>
                </div>
                <div className="mt-4 d-flex justify-content-center">
                  <p className="mb-0 text-black img-fluid mt-0" style={{width:"340px"}} >
                  Your details have been updated. You can now continue to explore OnePeople!
                  </p>
                </div>
                
              </div>
            </motion.div>
          </Modal>}

      {initialValue.email !== "" && (
        
        <Card className=" profile-card px-4 py-2 op-box border-0 overflow-hidden justify-content-center d-flex flex-wrap">
          {detailsUpdated && ( 
          <Modal  >
          <motion.div>
            <div className="container">

            </div>
          </motion.div>
          </Modal>)}
          {src && (
            <Modal>
              <motion.div
                onClick={(e) => {
                  setSrc(!src);
                  profilepic.current.value = "";
                }}
                transition={{ duration: 0.1 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="crop-container"
              >
                <div className="inner" onClick={(e) => e.stopPropagation()}>
                  <h4 className="text-center">Drag to Reposition</h4>
                  <span
                    className="close"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSrc(!src);
                      profilepic.current.value = "";
                    }}
                  >
                    <img
                      src={cross}
                      className="exit-icon"
                      alt="Exit Modal"
                    />
                  </span>
                  <div className="crop-area">
                    <Cropper
                      image={src}
                      crop={crop}
                     
                      zoom={zoom}
                      cropShape={"round"}
                      guides={false}
                   
                      
                      ref={cropperRef}
                      aspect={1}
                   
                      zoomSpeed={1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                  <div className="OPGroupButton text-center p-4 pb-0">
                    <button
                      className="default-button"
                      onClick={handleSubmitPhoto}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </motion.div>
            </Modal>
          )}
          <Card.Body className="text-center">
            <div className="d-flex flex-wrap align-items-center">
              <h4 className="mb-0 fw-semibold mb-0 fw-semibold text-left d-flex align-items-center text-black">
                <span
                  className="icon1 d-flex align-items-center justify-content-center me-3 FS14"
                  style={{
                    width: "1.25rem",
                    height: "1.25rem",
                    backgroundColor: "#66a3c4",
                    color: "white",
                    border: "0.125rem solid black",
                  }}
                >
                  i
                </span>{" "}
                My Details
              </h4>
            </div>
            <div>
              <form className="" onSubmit={submitForm}>
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                  <div className="position-relative">
                    <div className={"profile_picture position-relative mt-3 justify-content-center d-flex "+(picView && 'active')}>
                      {picView ? (
                        <img src={picView} className="img-fluid w-100" alt="Profile" />
                      ) : (
                        <FontAwesomeIcon icon={solid("user")} />
                      )}
                    </div>
                    {isVerified && (
                      <span className="profile-verified-check">
                        <FontAwesomeIcon icon={solid("check")} />
                      </span>
                    )}
                  </div>
                  <div type="file" style={{ display: "none" }}>
                    <input
                      type="file"
                      ref={profilepic}
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </div>
                  <a
                    href="/#"
                    className="col-12 mt-3"
                    onClick={(e) => {
                      e.preventDefault();
                      profilepic.current.click();
                    }}
                  >
                    Change profile picture
                  </a>
                </div>
                <div className="text-left mt-4">
                  <div className="OPinput-group text-start">
                    <div className="d-flex">
                      <label htmlFor="fullname" className="">Displayed Name</label>
                      {errors.fullname.length > 0 && (
                        <ShowError errors={errors.fullname} />
                      )}
                    </div>
                    <Col lg={12}>
                      <input
                        id="fullname"
                        name="fullname"
                        type={"text"}
                        className={"col-12 FS12 mb-2 hl-border"}
                        onChange={validateField}
                        defaultValue={initialValue.fullname}
                        ref={in_displayname}
                        disabled={userIsLoading}
                        
                      />
                    </Col>
                  </div>
                  <div className="OPinput-group text-start">
                    <div className="d-flex">
                      <label htmlFor="email" className="">Email</label>
                      {errors.email.length > 0 && (
                        <ShowError errors={errors.email} />
                      )}
                    </div>
                    <Col lg={12}>
                      <input
                        id="email"
                        name="email"
                        type={"email"}
                        ref={in_email}
                        onChange={validateField}
                        defaultValue={initialValue.email}
                        className={"col-12 FS12 mb-2 hl-border"}
                        disabled={userIsLoading}
                        autoComplete="false"
                      />
                    </Col>
                  </div>
                  <div className="OPinput-group text-start">
                    <div className="d-flex">
                      <label htmlFor="oldPassword" className="">Current Password</label>
                      {errors.oldPassword.length > 0 && (
                        <ShowError errors={errors.oldPassword} />
                      )}
                    </div>
                    <Col lg={12}>
                      <div className="d-flex flex-wrap position-relative input-with-toggle mb-2">
                        <input
                          id="oldPassword"
                          name="oldPassword"
                          type={showPassword.password ? "text" : "password"}
                          className={"col-12 FS12 hl-border"}
                          onChange={validatePasswordChange}
                          ref={in_oldPassword}
                          autoComplete="new-password"
                          disabled={userIsLoading}
                        />
                        <button
                          className="border-0 position-absolute"
                          type="button"
                          onClick={(e) => togglePasswordInput("password", e)}
                        >
                          {showPassword.password ? (
                            <motion.img
                              whileHover={{ scale: 1.1 }}
                              src={hidePw}
                              style={{ width: "1.25rem", height: "1.25rem" }}
                            />
                          ) : (
                            <motion.img
                              whileHover={{ scale: 1.1 }}
                              src={showPw}
                              style={{ width: "1.25rem", height: "1.25rem" }}
                            />
                          )}
                        </button>
                      </div>
                    </Col>
                  </div>
                  <div className="OPinput-group text-start">
                    <div className="d-flex">
                      <label htmlFor="newPassword" className="">New Password</label>
                      {errors.newPassword.length > 0 && (
                        <ShowError errors={errors.newPassword} />
                      )}
                    </div>

                    <Col lg={12}>
                      <div className="d-flex position-relative input-with-toggle mb-2">
                        <input
                          id="newPassword"
                          name="newPassword"
                          type={showPassword.newPassword ? "text" : "password"}
                          className="col-12 FS12 hl-border"
                          ref={in_newPassword}
                          disabled={userIsLoading}
                          onChange={validatePasswordChange}
                          autoComplete="false"
                        />
                        <button
                          className="border-0 position-absolute"
                          type="button"
                          onClick={(e) => togglePasswordInput("newPassword", e)}
                        >
                          {showPassword.newPassword ? (
                            <motion.img
                              whileHover={{ scale: 1.1 }}
                              src={hidePw}
                              style={{ width: "1.25rem", height: "1.25rem" }}
                            />
                          ) : (
                            <motion.img
                              whileHover={{ scale: 1.1 }}
                              src={showPw}
                              style={{ width: "1.25rem", height: "1.25rem" }}
                            />
                          )}
                        </button>
                      </div>
                    </Col>
                  </div>
                  <div className="OPinput-group text-start">
                    <div className="d-flex">
                      <label htmlFor="confirmPassword" className="">Re-enter New Password</label>
                      {errors.confirmPassword.length > 0 && (
                        <ShowError errors={errors.confirmPassword} />
                      )}
                    </div>
                    <Col lg={12}>
                      <div className="d-flex position-relative input-with-toggle mb-2">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type={
                            showPassword.confirmPassword ? "text" : "password"
                          }
                          className="col-12 FS12 hl-border"
                          onChange={validatePasswordChange}
                          ref={in_confirmPassword}
                          disabled={userIsLoading}
                          autoComplete="false"
                        />
                        <button
                          className="border-0 position-absolute"
                          type="button"
                          onClick={(e) =>
                            togglePasswordInput("confirmPassword", e)
                          }
                        >
                          {showPassword.confirmPassword ? (
                            <motion.img
                              whileHover={{ scale: 1.1 }}
                              src={hidePw}
                              style={{ width: "1.25rem", height: "1.25rem" }}
                            />
                          ) : (
                            <motion.img
                              whileHover={{ scale: 1.1 }}
                              src={showPw}
                              style={{ width: "1.25rem", height: "1.25rem" }}
                            />
                          )}
                        </button>
                      </div>
                    </Col>
                  </div>
                  <div className="mt-3 OPGroupButton d-flex ">
                    <motion.button
                      disabled={userIsLoading}
                      animate={
                        userIsLoading
                          ? { backgroundColor: "#ccc", cursor: "not-allowed" }
                          : {}
                      }
                      type="submit"
                      onClick={submitForm}
                      className="default-button  img-fluid   mx-auto justify-content-center d-flex"
                    >
                      Update
                    </motion.button>
                  </div>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
export default UserCardDetails;
