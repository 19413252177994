import {AES,enc,SHA256} from "crypto-js"; 
import axiosConfig from "./axiosConfig";
import axiosWithCacheConfig from "./axiosWithCacheConfig"
import auth from "./auth";
import localStorageHelper from "./localStorageHelper";



var photoHelper = (function () {
  // Set a cookie with the given name, value, and expiration time
  function setphotocache(data){
   const encrypted = AES.encrypt(data, process.env.REACT_APP_MESSAGE_SECRET).toString();

   localStorageHelper.setItem(SHA256("image-"+auth.getId()).toString(),encrypted);
  }

  function getphotocache(){
   const data = localStorageHelper.getItem(SHA256("image-"+auth.getId()).toString())
   if(data == null ){
      return null;
   }
   const decrypted = AES.decrypt(data,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
   return decrypted;
   }




  function base64ToBlobUrl(base64String, mimeType) {
   // Decode the Base64 string into binary data
   const binaryString = atob(base64String.split(",")[1]);

   // Convert the binary data into a Uint8Array
   const uint8Array = new Uint8Array(binaryString.length);
   for (let i = 0; i < binaryString.length; i++) {
     uint8Array[i] = binaryString.charCodeAt(i);
   }

   // Create a Blob from the Uint8Array
   const blob = new Blob([uint8Array], { type: mimeType });

   // Generate a Blob URL
   const blobUrl = URL.createObjectURL(blob);
   return blobUrl;
 }

  
  const getPhoto = () => {
   return new Promise(function(resolve,reject){
      let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3000/op/v1/photo":"https://api.onepeople.online/op/v1/photo";
      axiosWithCacheConfig
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((e) => {
         
        resolve((e.data))
      }).catch(e=>{
        auth.checkErrors(e);
        resolve({message: 'noimage'})
      });
   })
 };



  var get =async ()=>{
   let image = getphotocache("photo");
   // eslint-disable-next-line
   if(image== null){
      let photo = await getPhoto();
    
      if(photo.message === "noimage"){
         return null;
      }else{
         setphotocache(photo)
        
         let img = base64ToBlobUrl(photo);
         return {
            base64:photo,
            blob:img
         };
         }
     }else{

      let img = base64ToBlobUrl(image);
    
      return {
         base64:image,
         blob:img
      };
     }

   };
  var set = (photo)=>{
   setphotocache(photo);

  }
  return {
    get,
    set
  };
})();

export default photoHelper;