import Result from "../result/Result.js";
import OPSpinner from "../../others/OPSpinner.js";
import { useState, useEffect } from "react";
import { isPast } from "../../../helpers/date.js";
import auth from "../../../helpers/auth.js";

//Custom Hooks
// import ResultHook from "../../../customHooks/ResultsHook.js";
import axiosConfig from "../../../helpers/axiosConfig";
import axiosWithCacheConfig from "../../../helpers/axiosWithCacheConfig.js"
function Results(props) {
  const { referendum, setReferendum } = props;

  // let resultsHeight = ResultHook();
  // eslint-disable-next-line
  const [initResultsHeight, setInitResultsHeight] = useState(null);
  const [tags, setTags] = useState([]);
  const hasPast = referendum.is_past;
  const [updatingOption, setUpdatingOption] = useState("not updated");

  useEffect(() => {
    if (hasPast) {
      setUpdatingOption("updating");

      let headers = auth.isOnline()
        ? { Authorization: `Bearer ${auth.getToken()}` }
        : {};
        let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/${referendum.slug}/result`:`https://api.onepeople.online/op/v1/referendums/${referendum.slug}/result`;
        axiosWithCacheConfig
        .get(
          url,
          {
            headers,
          }
        )
        .then((res) => {
          if (res.data.success) {
            let status = res.data.status;
            if(status !== 'processing') {
              let result = res.data.result;
              setReferendum((old_ref) => {
                let new_ref = { ...old_ref };
                new_ref.options = result;
                return new_ref;
              });

              let tempTags = [];
              let first_option_percentage = result[0].percentage;
              for (let i = 0; i < result.length; i++) {
                if (i === 0) {
                  if (result[0].percentage === result[1].percentage) {
                    if (first_option_percentage === "0%") {
                      tempTags.push("Undecided");
                    } else {
                      tempTags.push("Tie");
                    }
                  } else {
                    tempTags.push("Majority");
                  }
                } else {
                  if (first_option_percentage === "0%") {
                    tempTags.push("Undecided");
                  } else if (result[i].percentage === first_option_percentage) {
                    if (tempTags[0] === "Tie") {
                      tempTags.push("Tie");
                    }
                  } else {
                    tempTags.push("Others");
                  }
                }
              }
              setTags(tempTags);
              setUpdatingOption("updated");
            } else {
              setUpdatingOption('processing');
            }
          }
        })
        .catch((errs) => {
          auth.checkErrors(errs);
          setUpdatingOption("error");
        })
    }

    computeResultMaxHeight();
  }, 
  // eslint-disable-next-line
  []);

  function computeResultMaxHeight() {
    let main = document.getElementsByClassName("ref-view");
    let refHeader = document.getElementsByClassName("ref-view-header");
    let refChoices = document.getElementsByClassName(
      "ref-title-choices-container"
    );
    let refDivider = document.getElementsByClassName("ref-view-divider");
    let refResultHeader = document.getElementsByClassName("ref-res-title");
    let refFooter = document.getElementsByClassName("footer");

    let maxHeight =
      main[0].clientHeight -
      (refHeader[0].clientHeight +
        refChoices[0].clientHeight +
        refDivider[0].clientHeight +
        refResultHeader[0].clientHeight 
        + (refFooter[0].clientHeight * 2.5)
        );
    if (window.innerWidth > 1000) {
      var cont = document.getElementsByClassName("ref-view-main-content")[0];
      var style = cont.currentStyle || getComputedStyle(cont);

      let unneeded_styles =
        parseFloat(style.marginTop) +
        parseFloat(style.paddingBottom) +
        parseFloat(style.paddingTop);
       

        setInitResultsHeight((maxHeight - unneeded_styles) + 'px');
    }
  }

  return (
    <>
      <div className="col-10 ref-view-divider">
        <hr className="mt-0" />
      </div>
      <div className="col-12">
        <div className="ref-res mt-4">
          <h2 className="ref-res-title text-black mb-0 pb-3">
            <span className="ref-res-title-icon me-3">&#128221;</span>
            Results breakdown
          </h2>
          {updatingOption === "updating" && (
            <div className="ref-res-bd-ld d-flex flex-column justify-content-center align-items-center px-3 text-gray">
              <OPSpinner />
              <p className="ld-text">Retrieving Result...</p>
            </div>
          )}

          {updatingOption === "processing" && (
            <div className="ref-res-bd-ld d-flex flex-column justify-content-center align-items-center px-3 text-gray">
              <OPSpinner />
              <p className="ld-text">Results are being tallied. Please try again later.</p>
            </div>
          )}

          {updatingOption === "error" && (
            <div className="ref-res-bd-ld d-flex flex-column justify-content-center align-items-center px-3">
              {/* <OPSpinner /> */}
              <p className="ld-text text-gray m-0">There was an error while retrieving the result.</p>
              <p className="text-gray m-0">Please try again.</p>
            </div>
          )}

          {updatingOption === "updated" && (
            <div
              className="row ref-res-bd mz-scroll"
              // style={{ maxHeight: resultsHeight ?? initResultsHeight }}
            >
              {referendum.options &&
                referendum.options.map((option, index) => {
                  return (
                    <div
                      className="col-12 col-md-12 col-lg-6 px-3 pb-4 mb-2"
                      key={index}
                    >
                      <Result
                        referendum={referendum}
                        setReferendum={setReferendum}
                        option={option}
                        tag={tags[index]}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Results;
