import { useEffect, useState } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import auth from "../../helpers/auth";
import OPSpinner from "../../components/others/OPSpinner";
import MetadataSetup from "../../components/MetadataSetup.js";

import axiosConfig from "../../helpers/axiosConfig";
import axiosWithCacheConfig from "../../helpers/axiosWithCacheConfig"
import Footer from "../../components/layouts/footer.js";
import { hasMainContentScroll } from "../../helpers/jsHelper";
import Log from "../../components/analytics/Log.jsx";

function LeaderBoard() {
  // const countryselect = useRef();
  // const timeSelect = useRef();
  const root_url = "https://api.onepeople.online/op/v1";

  const [winnersRanking, setWinnersRanking] = useState([]);
  const [winnersStatus, setWinnersStatus] = useState("updating");

  // const [loserssRanking, setLosersRanking] = useState([]);
  // const [loserssStatus, setLosersStatus] = useState("updating");

  const [insightsRanking, setInsightsRanking] = useState([]);
  const [insightsStatus, setInsightsStatus] = useState("updating");

  const [userWinnerRanking, setUserWinnerRanking] = useState(null);
  // const [userLoserRanking, setUserLoserRanking] = useState(null);
  const [userInsightsRanking, setUserInsightsRanking] = useState(null);


  useEffect(() => {
    getRanking();
    hasMainContentScroll("ld-main-content");
  }, []);

  function getRanking() {
    let headers = {};
    if (auth.isOnline()) {
      headers["Authorization"] = `Bearer ${auth.getToken()}`;
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/users/leaderboards`:`${root_url}/referendums/users/leaderboards`;
    axiosWithCacheConfig
      .get(url, {
        headers,
      })
      .then((res) => {
        if (res.data.success) {
          let top_winners = res.data.top_winners;
          // let top_losers = res.data.top_losers;
          let top_insights = res.data.top_insights;

          setWinnersRanking(top_winners.all);
          if (top_winners.user.length > 0) {
            setUserWinnerRanking(top_winners.user[0]);
          }

          // setLosersRanking(top_losers.all);
          // if (top_losers.user.length > 0) {
          //   setUserLoserRanking(top_losers.user[0]);
          // }

          setInsightsRanking(top_insights.all);
          if (top_insights.user.length > 0) {
            setUserInsightsRanking(top_insights.user[0]);
          }
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        console.log(errs);
      })
      .finally(() => {
        setWinnersStatus("updated");
        // setLosersStatus("updated");
        setInsightsStatus("updated");
        hasMainContentScroll("ld-main-content");
      });
  }

  window.addEventListener("resize", () => {
    hasMainContentScroll("ld-main-content");
  })

  // const selectChange = () => {
  //   console.log(countryselect.current.value);
  //   console.log(timeSelect.current.value);
  // };
  const LeaderBoardCard = ({ ranking, user_rank, status, title }) => {
    return (
      <>
        <Card className="op-box OPcard-rounded mt-3">
          <Card.Header className="ld-header clear-header pt-3 pb-0 pb-md-3 fblack FS18">
            <h5 style={{ fontFamily: "Nexa", fontWeight:"800" }}>{title}</h5>
            <h6>
              Your rank:{" "}
              {user_rank !== null
                ? `${user_rank.rank} (${title === 'Top Pundits' ? user_rank.win_rate + "%" : user_rank.insights})`
                : "Unranked"}
            </h6>
          </Card.Header>
          <Card.Body className="pt-md-1 pt-lg-2">
            <div className="div-table">
              <div className="div-table-header ">
                <Container>
                  <Row className="FS12 text-black">
                    <Col className={`col-6 px-2`}>Name</Col>
                    {title === 'Top Pundits' && (
                      <Col className="col-3 px-2 text-center">Total Votes</Col>
                    )}
                    <Col className={`${title === 'Most Insightful' ? 'col-4 col-md-3 offset-2 offset-md-3 ' : 'col-3'} px-1 text-center`}>
                      {title === "Most Insightful"
                        ? "Voted Insightful"
                        : "Win Rate (%)"}
                    </Col>
                  </Row>
                  <div className="p-0 m-0">
                    {ranking.length > 0 &&
                      status === "updated" &&
                      ranking.map((user, index) => {
                        return (
                          <Row
                            key={index}
                            className={`FS12 ${
                              index === 0
                                ? "mt-3 fblack gold-rounded"
                                : index === 1
                                ? "mt-2 fblack gray-rounded"
                                : index === 2
                                ? "mt-2 fblack brown-rounded"
                                : "mt-2 fblack"
                            } px-2`}
                          >
                            <Col className="col-6 d-flex align-items-center">
                              {index === 0
                                ? "🥇"
                                : index === 1
                                ? "🥈"
                                : index === 2
                                ? "🥉"
                                : ""}{" "}
                              {user.name}
                            </Col>
                            {title === 'Top Pundits' && (
                              <>
                                <Col className="col-3 text-center d-flex align-items-center justify-content-center">
                                  {user.total_votes}
                                </Col>
                                
                                <Col className="col-3 text-center d-flex align-items-center justify-content-center">
                                  {`${user.win_rate}%`}
                                </Col>
                              </>
                            )}
                            {title === 'Most Insightful' && (
                              <Col className="col-4 col-md-3 offset-2 offset-md-3 text-center d-flex align-items-center justify-content-center">
                                {user.insights ?? `${user.win_rate}%`}
                              </Col>
                            )}
                          </Row>
                        );
                      })}
                    {status === "updating" && (
                      <div className="d-flex justify-content-center align-items-center flex-column my-5">
                        <OPSpinner size={"med"} />
                        <span>Loading...</span>
                      </div>
                    )}

                    {status === "updated" && ranking.length === 0 && (
                      <div
                        className="text-center d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "12.5rem" }}
                      >
                        <div className="text-gray">
                          Ranking has not yet updated
                        </div>
                      </div>
                    )}
                  </div>
                </Container>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="ld-footer-rank clear-header text-black">
            Your rank:{" "}
            {user_rank !== null
              ? `${user_rank.rank} (${title === 'Most Insightful' ? user_rank.insights : user_rank.win_rate +"%"})`
              : "Unranked"}
          </Card.Footer>
        </Card>
      </>
    );
  };

  return (
    <Log logImpression event="page-view" page="Leaderboards">
      <MetadataSetup 
        title="Leaderboards | OnePeople" 
        canonical="https://onepeople.online/leaderboards/"
        description="Leaderboards Page of OnePeople Online"
      />
      <Card
        className="card-transparent op-box ld-main-content px-0 px-lg-3 pt-4 pb-0 mb-3 mt-1 mz-scroll"
        style={{ borderRadius: "1.875rem" }}
      >
        <Card.Header className="d-flex clear-header p-0 fblack px-3">
          <h4 className="fw-semibold gp-header-text">🥇 Leaderboards</h4>
        </Card.Header>
        <Card.Body className="pt-0 pb-0 mt-2">
          <Row className="ld-row mz-scroll">
            <Col xxl={4} xl={6} lg={12} md={12} className="pb-1 pb-md-1 pb-lg-4">
              <LeaderBoardCard
                ranking={winnersRanking}
                user_rank={userWinnerRanking}
                status={winnersStatus}
                title="Top Pundits"
              />
            </Col>
            {/* <Col xl={4} lg={4} md={12}>
              <LeaderBoardCard
                ranking={loserssRanking}
                user_rank={userLoserRanking}
                status={loserssStatus}
                title="Top Loser"
              />
            </Col> */}
            <Col xxl={4} xl={6} lg={12} md={12} className="pb-4 pb-md-4 pb-lg-4">
              <LeaderBoardCard
                ranking={insightsRanking}
                user_rank={userInsightsRanking}
                status={insightsStatus}
                title="Most Insightful"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Footer customClass={"inside-footer"}/>
    </Log>
  );
}
export default LeaderBoard;
