import localStorageHelper from "./localStorageHelper";
import auth from "./auth";
import {AES,enc,SHA256} from "crypto-js"; 
var localCacheHelper = {
  set:(data,keyURL)=>{
   const encrypted = AES.encrypt(JSON.stringify(data), process.env.REACT_APP_MESSAGE_SECRET).toString();

   localStorageHelper.setItem(SHA256("localCacheHelper-"+keyURL+"-"+auth.getId()).toString(),encrypted);
   },
   get:(keyURL)=>{
   const data = localStorageHelper.getItem(SHA256("localCacheHelper-"+keyURL+"-"+auth.getId()).toString())
   if(data == null ){
      return [];
   }
   const decrypted = AES.decrypt(data,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
   return JSON.parse( decrypted);
   }

 };

 export default localCacheHelper;