import { motion } from "framer-motion";

import cross from "../../assets/images/others/cross.svg";
import { useState } from "react";
import { setCookie } from "../../helpers/browserHelper.js";


export default function ConsentForm({ isOpen, setIsOpen }) {
    const OpenningAnimation = { scale: 1, translateY: "-50%" };
    const ClosingAnimation = { scale: 0, translateY: "-50%" };

    function consentAgreement(consent) {
        setCookie("consentAgreement", consent, 400);
        setIsOpen(false);
    }

    return (
        <motion.div className="consent-container">
            <div className="consent-info">
                <p>We use cookies to enhance your browsing experience and to help us understand how our website is used, so we can improve its functionality. By clicking <strong>Accept</strong>, you agree to the use of non-essential cookies. You can read more in our <a href="/privacy-policy/" target="_blank">Privacy Policy.</a></p>
            </div>
            <div className={`consent-agreement`}>
                <button onClick={() => consentAgreement("Accept")} type="button" className="accept-btn px-3 py-1 me-0 me-md-2">Accept</button>
                <button onClick={() => consentAgreement("Decline")} type="button" className="decline-btn px-3 py-1">Decline</button>
            </div>
        </motion.div>
    )
}