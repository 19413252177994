import { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import InputChoices from "../../components/referendums/owner/InputChoices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faClock,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import auth from "../../helpers/auth";
import validator from "validator";
import { motion } from "framer-motion";
import { hasDuplicateEntry } from "../../helpers/validation.js";
import MetadataSetup from "../../components/MetadataSetup.js";
import Log from "../../components/analytics/Log.jsx";

import {
  isPastByServer,
  dateNowByServer,
} from "../../helpers/date.js";

// Components
import SelectReferendum from "../../components/referendums/SelectReferendum";
import ReferendumPublish from "../../components/referendums/owner/ReferendumPublish";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputCounter from "./InputCounter";
import axiosConfig from "../../helpers/axiosConfig";
import axiosWithCacheConfig from "../../helpers/axiosWithCacheConfig.js";

import categoryCacheHelper from "../../helpers/categoryCacheHelper";
import countryCacheHelper from "../../helpers/countryCacheHelper";
import { useSelector } from "react-redux";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";

function CreateReferendum() {
  const serverTime = serverTimeHelper.get();
  const initServerTime = serverTimeHelper.clientTime();

  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  const [showPreview, setShowPreview] = useState(false);
  const [referendum, setReferendum] = useState({});

  const initErrors = {
    title: null,
    choices: null,
    category: null,
    country: null,
    date: null,
    others: null,
  };

  const [errors, setErrors] = useState(initErrors);

  //Initial Values
  const [title, setTitle] = useState("");
  const choicesInitialState = [
    { key: 0, value: "" },
    { key: 1, value: "" },
  ];
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);

  const initDate = {
    min: moment(dateNowByServer(serverTime, initServerTime)).toDate(),
    max: moment(dateNowByServer(serverTime, initServerTime)).add(7, "days").toDate(),
  };

  const [choices, setChoices] = useState(choicesInitialState);
  const [category, setCategory] = useState(null);

  const [country, setCountry] = useState(null);
  const [date, setDate] = useState(moment(dateNowByServer(serverTime, initServerTime)).add(1, "day").toDate());
  const [time, setTime] = useState(moment(dateNowByServer(serverTime, initServerTime)).add(1, "day").toDate());
  const pathname = window.location.pathname;

  useEffect(
    () => {
      if (!auth.isOnline()) {
        navigate("/");
      }
      if (queryParameters.get("type") !== 'preview') {
        setShowPreview(false);
      } else {
        onPreview(false);
      }
      fetchCategories();
      fetchCountries();
    },
    // eslint-disable-next-line
    [pathname]
  );

  async function fetchCategories() {
    let cacheCategory = categoryCacheHelper.get();
    if (categoryCacheHelper.get().length !== 0) {
      let datas = processCategories(cacheCategory)
      setCategories(datas);
      return false;
    }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3001/op/v1/options/categories" : "https://api.onepeople.online/op/v1/options/categories";
    axiosConfig
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        })
      .then((res) => {
        if (res.data.success) {
          let cg = processCategories(res.data.categories)
          setCategories(cg);
        }
      })
      .catch((err) => {
        auth.checkErrors(err);
        setErrors({ ...errors, category: "Something went wrong." });
      });
  }
  async function fetchCountries() {

    // let cacheCountry = countryCacheHelper.get();
    // if (countryCacheHelper.get().length !== 0) {
    //   let datas = processCountries(cacheCountry);
    //   setCountries(datas);
    //   return false;
    // }
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3001/op/v1/options/countries" : "https://api.onepeople.online/op/v1/options/countries";
    axiosWithCacheConfig
      .get(url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        })
      .then((res) => {
        if (res.data.success) {
          let ct = processCountries(res.data.countries)
          if (res.data.countries.length > 0) {
            countryCacheHelper.set(res.data.countries)
          }
          setCountries(ct);
        }
      })
      .catch((err) => {
        auth.checkErrors(err);
        setErrors({ ...errors, country: "Something went wrong." });
      });
  }

  function processCategories(categories_response) {
    return categories_response.map((category, index) => {
      return { value: category.slug, label: category.category };
    });
  }

  function processCountries(countries_response) {
    return countries_response.map((country, index) => {
      return { value: country.slug, label: country.country };
    });
  }

  function onChangeTitle(e) {
    if (e.target.value.length <= 300) {
      setTitle(e.target.value);
      setErrors({ ...errors, title: null, others: null });
    }
  }

  function onChangeChoice(choices) {
    setChoices(choices);
    setErrors({ ...errors, choices: null, others: null });
  }

  function onChangeCategory(e) {
    setCategory(e);
    setErrors({ ...errors, category: null, others: null });
  }

  function onChangeCountry(e) {
    setCountry(e);
    setErrors({ ...errors, country: null, others: null });
  }

  function onChangeDate(e) {
    setDate(e);
    setErrors({ ...errors, date: null, others: null });
  }

  function onChangeTime(e) {
    setTime(e);
    setErrors({ ...errors, date: null, others: null });
  }

  function onPreview(nav_to = true) {
    //init date's time using time selector
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());
    date.setMilliseconds(time.getMilliseconds());

    let errorTrackers = { ...errors };
    let hasErrors = false;

    if (validator.isEmpty(title)) {
      errorTrackers.title = "Referendum topic is required";
      hasErrors = true;
    } else if (!validator.isLength(title, { min: 1, max: 300 })) {
      errorTrackers.title = "Your referendum is too long";
      hasErrors = true;
    }

    choices.forEach((choice) => {
      choice.value = choice.value.trim();
    })

    choices.map((choice, index) => {
      if (validator.isEmpty(choice.value)) {
        errorTrackers.choices = "One or more choices may be empty";
        hasErrors = true;
      } else if (!validator.isLength(choice.value, { min: 1, max: 50 })) {
        errorTrackers.choices = "One or more choices may be too long";
        hasErrors = true;
      }
      return choice;
    });

    let hasDuplicate = hasDuplicateEntry(choices.map((choice) => choice.value));
    if (hasDuplicate) {
      errorTrackers.choices = "One or more choices may be duplicated";
      hasErrors = true;
    }

    if (category === null) {
      errorTrackers.category = "Please select a category";
      hasErrors = true;
    }

    if (country === null) {
      errorTrackers.country = "Please select a country";
      hasErrors = true;
    }

    // if (isPast(date)) {
    //   errorTrackers.date = "Please select a valid date and time. (max 7 days)";
    //   hasErrors = true;
    // }

    const dateNow = dateNowByServer(serverTime, initServerTime);
    if (isPastByServer(date, dateNow)) {
      errorTrackers.date = "Please select a valid date and time. (max 7 days)";
      hasErrors = true;
    }

    setErrors(errorTrackers);
    if (hasErrors) return;

    let ref = {
      title,
      created_at: dateNow,
      end_at: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      // created_at: initDateForPassing(dateByTimezone()),
      // end_at: initDateForPassing(date),
      owner: true,
      followed: false,
      options: choices.map((choice) => {
        return { option: choice.value, active: false };
      }),
      country,
      categories: [category],
    };
    setReferendum(ref);
    setShowPreview(true);
    if (nav_to) {
      navigate("/create?type=preview");
    }
  }
  return (
    <Log logImpression event="page-view" page="Create Referendum">
      <div
        className="main d-grid ref-view ref-create"
        style={{
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        <MetadataSetup
          title="Create Referendum | OnePeople"
          canonical="https://onepeople.online/create/"
          description="Create Referendum Page of OnePeople Online"
        />
        <div className="center row pb-3 mt-1">
          {showPreview && referendum !== null && (
            <ReferendumPublish
              referendum={referendum}
              setReferendum={setReferendum}
              setShowPreview={setShowPreview}
              errors={errors}
              setErrors={setErrors}
              showPreview={showPreview}
            />
          )}
          {!showPreview && (
            <motion.div
              className="col-12 col-md-12 col-lg-8 col-xl-5 col-xxl-3 mt-1"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Card
                style={{
                  minHeight: "18.75rem",
                  minWidth: "100%",
                  borderRadius: "1.875rem",
                  paddingTop: "1.875rem",
                  paddingBottom: "1.25rem",
                  // marginTop: "0.625rem",
                  // maxHeight: "43.0625rem"
                }}
                className="ref-view-main-content border-0 op-box ps-4 pe-3 justify-content-center d-flex mz-scroll-none"
              >
                <div className="d-flex align-items-center justify-content-between pe-5">
                  <div className="d-flex align-items-center">
                    <span
                      className="icon1 d-flex align-items-center justify-content-center me-2"
                      style={{ fontSize: "1.5rem" }}
                    >
                      ✍️
                    </span>
                    <h4 className="mb-0 fw-semibold gp-header-text">
                      Create Referendum
                    </h4>
                  </div>
                </div>
                <Card.Body className="p-0 ref-view-body row justify-content-center mz-scroll-none">
                  <form className="mt-4 ps-3 pe-3 cr-ref mz-scroll" autoComplete="off">
                    <div className="position-relative">
                      <div className="m-0 p-0 d-flex justify-content-between">
                        <label
                          htmlFor="ref-topic"
                          className="input-text text-gray"
                        >
                          Referendum Topic
                        </label>
                        {errors.title && (
                          <p className="m-0 p-0 input-text input-error text-red">
                            {errors.title}
                          </p>
                        )}
                      </div>
                      <textarea
                        id="ref-topic"
                        value={title}
                        autoFocus
                        rows="7"
                        className="w-100 hl-border"
                        onChange={onChangeTitle}
                      ></textarea>
                      <InputCounter max={300} inputText={title} />
                    </div>
                    <div className="mt-3">
                      <div className="m-0 p-0 d-flex justify-content-between">
                        <div className="input-text text-gray">Vote Choices</div>
                        {errors.choices && (
                          <p className="m-0 p-0 input-text input-error text-red">
                            {errors.choices}
                          </p>
                        )}
                      </div>
                      <InputChoices
                        choices={choices}
                        setChoices={setChoices}
                        onChangeChoice={onChangeChoice}
                      />
                    </div>
                    <div className="mt-3">
                      <div className="m-0 p-0 d-flex justify-content-between">
                        <label
                          htmlFor="ref_category"
                          className="input-text text-gray"
                        >
                          Referendum Category
                        </label>
                        {errors.category && (
                          <p className="m-0 p-0 input-text input-error text-red">
                            {errors.category}
                          </p>
                        )}
                      </div>
                      <SelectReferendum
                        inputId="ref_category"
                        customClass="ref_create_select"
                        options={categories}
                        onChange={onChangeCategory}
                        maxMenuHeight={"6rem"}
                        placeholder="Select Category"
                        defaultValue={category}
                        inputColor="#000"
                      />
                    </div>
                    <div className="mt-3">
                      <div className="m-0 p-0 d-flex justify-content-between">
                        <label
                          htmlFor="ref_country"
                          className="input-text text-gray"
                        >
                          Country
                        </label>
                        {errors.country && (
                          <p className="m-0 p-0 input-text input-error text-red">
                            {errors.country}
                          </p>
                        )}
                      </div>
                      <SelectReferendum
                        inputId="ref_country"
                        customClass="ref_create_select"
                        faIcon={faGlobe}
                        options={countries}
                        onChange={onChangeCountry}
                        maxMenuHeight={"6rem"}
                        placeholder="Select Country"
                        defaultValue={country}
                        inputColor="#000"
                      />
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <div className="m-0 p-0 d-flex justify-content-between">
                          <label htmlFor="closing_date" className="input-text text-gray">
                            Closing Date
                          </label>
                        </div>
                        <div className="position-relative d-select border-0">
                          <DatePicker
                            id="closing_date"
                            dateFormat="dd/MM/yyyy"
                            className="px-2 w-100"
                            placeholderText="Date"
                            selected={date ?? undefined}
                            onChange={onChangeDate}
                            startDate={initDate.min}
                            minDate={initDate.min}
                            maxDate={initDate.max}
                            style={{ width: "100%" }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faCalendarDays}
                            fontSize={"0.8rem"}
                            color="#3FA778"
                            aria-hidden="true"
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "50%",
                              transform: "translateY(-50%)",
                              color: "#ccc",
                              zIndex: "5",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="m-0 p-0 d-flex justify-content-between">
                          <label htmlFor="closing_time" className="input-text text-gray">
                            Closing Time
                          </label>
                        </div>
                        <div className="position-relative d-select border-0">
                          <DatePicker
                            id="closing_time"
                            className="px-2 w-100"
                            selected={time}
                            onChange={onChangeTime}
                            placeholderText="Time"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            popperPlacement="top"
                          />
                          <FontAwesomeIcon
                            icon={faClock}
                            fontSize={"0.8rem"}
                            color="#3FA778"
                            aria-hidden="true"
                            style={{
                              position: "absolute",
                              right: "0.5rem",
                              top: "50%",
                              transform: "translateY(-50%)",
                              color: "#ccc",
                              zIndex: "5",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="m-0 p-0 d-flex justify-content-between">
                      {errors.date && (
                        <p className="m-0 p-0 input-text input-error text-red">
                          {errors.date}
                        </p>
                      )}
                      {errors.others && (
                        <p className="m-0 p-0 input-text input-error text-red">
                          {errors.others}
                        </p>
                      )}
                    </div>
                    <div className="d-flex justify-content-center mt-5 mb-3">
                      <button
                        onClick={onPreview}
                        type="button"
                        className="op-btn create-btn px-3 py-3"
                      >
                        Create Referendum
                      </button>
                    </div>
                  </form>
                </Card.Body>
              </Card>
            </motion.div>
          )}
        </div>
      </div>
    </Log>
  );
}

export default CreateReferendum;
