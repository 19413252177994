import { Row, Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import Counter from "../others/Counter";
import auth from "../../helpers/auth";
import OPSpinner from "../others/OPSpinner";
import axiosConfig from "../../helpers/axiosConfig"
import axiosWithCacheConfig from "../../helpers/axiosWithCacheConfig.js"
import { hasMainContentScroll, getMyReferendumBodyHeight } from "../../helpers/jsHelper";

function MyStatistic() {
  // const countryselect = useRef();
  // const timeSelect = useRef();

  const [statistics, setStatistics] = useState({
    total_votes: 0,
    won: 0,
    lost: 0,
    total_insightful_inputs: 0,
    fav_categories: [],
  });
  const [statStatus, setStatStatus] = useState(null);

  useEffect(() => {
    getStatistics();
  }, []);

  function getStatistics() {
    setStatStatus("updating");
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? `http://127.0.0.1:3004/op/v1/referendums/users/statistics`:`https://api.onepeople.online/op/v1/referendums/users/statistics`;
    axiosWithCacheConfig
      .get(url, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let stats = res.data.statistics;
          setStatistics({
            total_votes: stats.total_votes,
            won: stats.won,
            lost: stats.lost,
            total_insightful_inputs: stats.total_insightful_inputs,
            fav_categories: stats.fav_categories,
          });
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
      })
      .finally(() => {
        setStatStatus("updated")
        getMyReferendumBodyHeight();
        hasMainContentScroll("my-history-content")
      });
  }

  // const selectChange = () => {
  //   console.log(countryselect.current.value);
  //   console.log(timeSelect.current.value);
  // };

  return (
    <>
      <Card
        className="mystat-card-history op-box py-4"
        style={{ borderRadius: "1.875rem" }}
      >
        <Card.Header className="d-flex align-items-center clear-header ps-4 py-0 fblack">
          <span
            className="icon1 d-flex align-items-center justify-content-center me-2"
            style={{ color: "white", fontSize: "1.5rem" }}
          >
            📊
          </span>
          <h4 className="fw-semibold gp-header-text"> My Statistics</h4>

          {/* <div className="controls flex-fill justify-content-end d-flex flex-wrap">
            <div className="me-3">
              <OPSelect
                changestate={() => {
                  selectChange();
                }}
                options={[
                  { value: "global", text: "Global" },
                  { value: "singapore", text: "Singapore" },
                ]}
                select={countryselect}
                placeholder="All Countries"
              />
            </div>
            <div>
              <OPSelect
                changestate={() => {
                  selectChange();
                }}
                options={[
                  { value: "season-all", text: "All Season" },
                  { value: "season-1", text: "Season 1" },
                ]}
                select={timeSelect}
                placeholder="All time..."
              />
            </div>
          </div> */}
        </Card.Header>
        <Card.Body className="py-0 mt-3">
          {statStatus === "updating" && (
            <Row>
              <Col lg={12} className="statistic-group-card">
                <div className="d-flex justify-content-center align-items-center flex-column my-3 ps-4">
                  <OPSpinner />
                  <span>Loading...</span>
                </div>
              </Col>
            </Row>
          )}
          {statStatus === "updated" && (
            <Row className="px-2 px-md-4 pt-0 pb-1">
              <Col lg={9} className="statistic-group-card">
                <Row className="h-100">
                  <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className="text-center flex-fill statistic-single-card">
                      <Card.Body className="p-2 py-3 d-flex flex-column">
                        <h5>Total Votes</h5>
                        <div className="number mt-2">
                          {statistics.total_votes === 0 ? (
                            "0"
                          ) : (
                            <Counter min={0} max={statistics.total_votes} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className=" text-center statistic-single-card">
                      <Card.Body className="p-2 py-3 d-flex flex-column">
                        <h5>Won</h5>
                        <div className="number mt-2">
                          {statistics.won === 0 ? (
                            "0"
                          ) : (
                            <Counter min={0} max={statistics.won} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className=" text-center statistic-single-card">
                      <Card.Body className="p-2 py-3 d-flex flex-column">
                        <h5>Lost</h5>
                        <div className="number mt-2">
                          {statistics.lost === 0 ? (
                            "0"
                          ) : (
                            <Counter min={0} max={statistics.lost} />
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className=" text-center statistic-single-card">
                      <Card.Body className="p-2 py-3 d-flex flex-column">
                        <h5>Win %</h5>
                        <div className="number mt-2">
                          {statistics.total_votes === 0 ||
                          statistics.won === 0 ? (
                            "0"
                          ) : (
                            <>
                              <Counter
                                min={0}
                                max={Math.round(
                                  (statistics.won / statistics.total_votes) *
                                    100
                                )}
                              />
                              %
                            </>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className=" text-center statistic-single-card">
                      <Card.Body className="p-2 py-3 d-flex flex-column">
                        <h5>Lose %</h5>
                        <div className="number mt-2">
                          {statistics.total_votes === 0 ||
                          statistics.lost === 0 ? (
                            "0"
                          ) : (
                            <>
                              <Counter
                                min={0}
                                max={Math.round(
                                  (statistics.lost / statistics.total_votes) *
                                    100
                                )}
                              />
                              %
                            </>
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className=" text-center flex-fill statistic-single-card">
                      <Card.Body className="p-2 py-3">
                        <h5>Insightful Inputs</h5>
                        <div
                          className="d-flex flex-column justify-content-center"
                          style={{ height: "100%" }}
                        >
                          <div className="number">
                            {statistics.total_insightful_inputs === 0 ? (
                              "0"
                            ) : (
                              <Counter
                                min={0}
                                max={statistics.total_insightful_inputs}
                              />
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col> */}
                  <Col className="col-4 col-md-4 col-lg-2 p-2">
                    <Card className="text-center statistic-single-card">
                      <Card.Body className="p-2 py-3 d-flex flex-column">
                        <h5>Insightful Inputs</h5>
                        <div className="number mt-2">
                          <Counter
                            min={0}
                            max={statistics.total_insightful_inputs}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12 col-md-12 col-lg-3 p-2">
                <Card className=" text-center flex-fill  statistic-single-card">
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <h5>Favorite Topic</h5>
                    <div className="number mt-2">
                      {statistics.fav_categories.length === 0 && `No Favourite`}

                      {statistics.fav_categories.length >= 1 &&
                        `${statistics.fav_categories[0].category}`}

                      {/* {statistics.fav_categories.length === 2 &&
                        `${statistics.fav_categories[0].category} and ${statistics.fav_categories[1].category}`} */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default MyStatistic;
