import axios from "axios";
import csrfHelper from "./csrfHelper";
import serverTimeHelper from "./serverTimeHelper";
import localCacheHelper from "./localCacheHelper";
const instance = axios.create();


instance.interceptors.request.use(async function (conf) {
   let serverTime = serverTimeHelper.get();
   let {url,params} = conf;
   let paramURL = new URLSearchParams(params).toString();

   let fullURL = url+(paramURL !== "" && paramURL !== undefined ? "?"+paramURL:"");

   if(url !== undefined){
      let cache =await localCacheHelper.get(fullURL);
   
      if (cache.keyURL !== undefined){
         conf.headers['If-Modified-Since'] = cache.ifModifiedSince;
      }
    
   }
   await csrfHelper.get().then((e) => {
      conf.headers['X-CSRF-TOKEN'] = e;
   });


   return conf;
})
instance.interceptors.response.use(async function (res) {

   let { config, headers, data } = res;
   let cacheData = {
      keyURL: null,
      data: null,
      ifModifiedSince:null
   };
   let donotCacheonFailure = false;
   if (config.url !== undefined) {
      let {params,url} = config;
      let paramURL = new URLSearchParams(params).toString();

      let fullURL = url+(paramURL !== "" && paramURL !== undefined ? "?"+paramURL:"");
     
      cacheData.keyURL = fullURL;
   
      if (data !== undefined) {
         cacheData.data= data
      }
    
      if (headers['last-modified'] !== undefined) {
         cacheData.ifModifiedSince = headers['last-modified'];
      }else{
         donotCacheonFailure = true;
      }

      if(donotCacheonFailure==false){
         localCacheHelper.set(cacheData,cacheData.keyURL);
      }
    
   }
  

   
   return res;

},async (error)=>{
   if (error.response && error.response.status === 304) {
      let {config} = error;
      let {params,url}= config;
      let paramURL = new URLSearchParams(params).toString();
      
      let data,cache = null;
    
      let fullURL = url+(paramURL !== "" && paramURL !== undefined ? "?"+paramURL:"");

      if(url !== undefined){
        
         cache = await localCacheHelper.get(fullURL);
         if(cache.data !== undefined){
        
            return Promise.resolve({
               data: cache.data,
               status: 304,
               statusText: "Not Modified",
               headers: error.response.headers,
               config: error.config,
             });
            

         }
        
      }
    
   }
   return Promise.reject(error);
})
export default instance;