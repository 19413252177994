import localStorageHelper from "./localStorageHelper";
import auth from "./auth";
import {AES,enc,SHA256} from "crypto-js"; 
import axiosConfig from "./axiosConfig.js";
import axiosWithCacheConfig from "./axiosWithCacheConfig.js"
import axios from "axios";
import serverTimeHelper from "./serverTimeHelper.js";


var messageHelper = {
  setUserMessage:(data)=>{
   const encrypted = AES.encrypt(JSON.stringify(data), process.env.REACT_APP_MESSAGE_SECRET).toString();

   localStorageHelper.setItem(SHA256("M-"+auth.getId()).toString(),encrypted);
   },
   getUserMessage:()=>{
   const data = localStorageHelper.getItem(SHA256("M-"+auth.getId()).toString())
   if(data == null ){
      return [];
   }
   const decrypted = AES.decrypt(data,process.env.REACT_APP_MESSAGE_SECRET).toString(enc.Utf8)
   return JSON.parse(decrypted);
   },
   getMessageCount:async (callback) => {
    try {
      if (auth.isOnline()) {
        
        let params;
        let isTimeExpired =serverTimeHelper.isExpired();
        let server = await serverTimeHelper.get();
 
        if(isTimeExpired){  
          params = { time:true };
        }else{
          params =  { };
        }

        let url = process.env.REACT_APP_ENVIRONMENT ==="development" ? "http://127.0.0.1:3002/op/v1/messages/count":"https://api.onepeople.online/op/v1/messages/count";
        
        return axiosConfig
          .get(url, {
            params: params,
            headers: {
              Authorization: `Bearer ${auth.getToken()}`,
            },
          })
          .then((e) => {
            callback(e.data.count)
            // getmessageCount();
            if(e.data.time != undefined){
              serverTimeHelper.set(e.data.time)
              return e.data.time
            }
            

          })
          .catch((e) => {
            auth.checkErrors(e);
            auth.checkErrors(e);
            callback(0);
          });
      }
    } catch (e) {
      // console.log(e)
      if (axios.isCancel(e)) {
        
      } else {
        console.error("Error fetching messages:", e);
        // getmessageCount(); // Reinitiate the long polling request after a timeout or other error
      }

      auth.logout();
      localStorage.clear();
      window.location.href = "/";
      // navigate("/");
      callback(0)
    }
   }

 };

 export default messageHelper;