import { Row, Col } from "react-bootstrap";
import MyStatistic from "../../components/history/MyStatistic";
import Suggestions from "../../pages/referendums/Suggestions";
import auth from "../../helpers/auth";
import { getVotingDurationByOrigServer, dateNowByServer } from "../../helpers/date";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import MetadataSetup from "../../components/MetadataSetup.js";
import Log from "../../components/analytics/Log.jsx";


import StatisticMyReferendum from "../../components/history/StatisticMyReferendum";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../helpers/axiosConfig"
import axiosWithCacheConfig from "../../helpers/axiosWithCacheConfig.js"
import Footer from "../../components/layouts/footer.js";
import { hasMainContentScroll, getMyReferendumBodyHeight } from "../../helpers/jsHelper";
import { useSelector } from "react-redux";
import serverTimeHelper from "../../helpers/serverTimeHelper.js";
function MyHistory() {

  const navigate = useNavigate();
  const [followedData, setFollowedData] = useState([]);
  const [followedDataStatus, setFollowedDataStatus] = useState("updating");

  const [interestedData, setInterestedData] = useState([]);
  const [interestedDataStatus, setInterestedDataStatus] = useState("updating");


  window.addEventListener("resize", () => {
    hasMainContentScroll("my-history-content");
    getMyReferendumBodyHeight();
  })

  useEffect(
    () => {
      if (!auth.isOnline()) {
        navigate("/");
      } else {
        getUserFollowedReferendums();
        getInterestedReferendums();
        hasMainContentScroll("my-history-content")
      }
    },
    // eslint-disable-next-line
    []
  );

  function getUserFollowedReferendums() {
    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?user=followed" : "https://api.onepeople.online/op/v1/referendums/suggestions?user=followed";
    axiosWithCacheConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setFollowedData);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        console.log(errs);
      })
      .finally(() => {
        setFollowedDataStatus("updated");
        hasMainContentScroll("my-history-content");
      });
  }

  function getInterestedReferendums() {

    let url = process.env.REACT_APP_ENVIRONMENT === "development" ? "http://127.0.0.1:3004/op/v1/referendums/suggestions?user=favourite" : "https://api.onepeople.online/op/v1/referendums/suggestions?user=favourite";
    axiosWithCacheConfig
      .get(
        url,
        {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setupSuggestions(res.data.referendums, setInterestedData);
        }
      })
      .catch((errs) => {
        auth.checkErrors(errs);
        console.log(errs);
      })
      .finally(() => {
        setInterestedDataStatus("updated");
        hasMainContentScroll("my-history-content");
      });
  }

  const setupSuggestions = (refs, setRef) => {
    refs = refs.slice(0, 4).map((ref, index) => {
      let dateNow = dateNowByServer(serverTimeHelper.get(), serverTimeHelper.clientTime())
      let tempStatus = getVotingDurationByOrigServer(ref.end_at, dateNow);
      ref = { ...ref, ...tempStatus };
      return ref;
    });
    setRef(refs);
  };

  return (
    <Log logImpression event="page-view" page="My History">
      <MetadataSetup
        title="My History | OnePeople"
        canonical="https://onepeople.online/my-history/"
        description="My History Page of OnePeople Online"
      />
      <Row className="pb-3 my-history-content">
        <Col xxl={9} xl={12} lg={12} md={12} className="mt-1">
          <Row className="main my-history">
            <motion.div
              className="my-statistics col-lg-12"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <MyStatistic />
            </motion.div>
            <motion.div
              className="my-refs col-lg-12 mt-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <StatisticMyReferendum />
            </motion.div>
          </Row>
        </Col>

        <Col xxl={3} xl={12} lg={12} md={12} className="suggestions-main-container mt-4 mt-xxl-1">
          <div className="row">
            <motion.div
              className="col-12"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Suggestions
                url="/search/"
                updateStatus={interestedDataStatus}
                minHeight="18.875rem"
                title="Interested in these?"
                unicode="&#128499;"
                referendums={interestedData}
                errorMsg={`No open referendums found`}
              />
            </motion.div>
            <motion.div
              className="col-12 mt-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3 }}
            >
              <Suggestions
                url="/my-follows/"
                updateStatus={followedDataStatus}
                minHeight="18.875rem"
                unicode="&#128276;"
                title="Followed"
                errorMsg={`No open referendums found`}
                referendums={followedData}
              />
            </motion.div>
          </div>
        </Col>
      </Row>
      <Footer customClass={"inside-footer"} />
    </Log>
  );
}

export default MyHistory;
